
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import EditUserModal from "@/components/EditUserModal.vue";
import LabelValueListItem from "@/components/LabelValueListItem.vue";
import AreaSelectorModal from "@/components/AreaSelectorModal.vue";
import GroupsSelectorModal from "@/components/GroupsSelectorModal.vue";
import HeadquarterSelectorModal from "@/components/HeadquarterSelectorModal.vue";
import * as Api from "@/types/graphql";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import { isCompanyReferent } from "@/utils/status";
import {
  GENDER_FEMALE_CODE,
  GENDER_MAN_CODE,
  GENDER_OTHER_CODE,
  getGenderText,
  getReferentName,
} from "@/utils/user";
import gql from "graphql-tag";
import _ from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: {
    Card,
    Btn,
    LabelValueListItem,
    EditUserModal,
    AreaSelectorModal,
    GroupsSelectorModal,
    HeadquarterSelectorModal,
  },
})
export default class UserData extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.selectedGroups)
  selectedGroups: Api.CodeDecodeChild[];
  @Action fetchUser;
  @Getter getArea;
  @Getter getSubGroup;
  @Mutation showError;
  @Mutation showSuccess;
  @Mutation setSelectedArea;
  @Mutation setSelectedHeadquarter;
  @Mutation setSelectedGroups;

  @Prop({ default: true })
  canEdit: boolean;

  editUser: Api.User = null;
  showEditUserModal = null;
  showAreaSelectorModal = false;
  showGroupsSelectorModal = false;
  showHeadquarterSelectorModal = false;

  admin1Role = RoleCodes.ADMIN1;
  admin2Role = RoleCodes.ADMIN2;
  userRole = RoleCodes.USER;

  genderData = [
    { label: "Uomo", value: GENDER_MAN_CODE },
    { label: "Donna", value: GENDER_FEMALE_CODE },
    { label: "Altro", value: GENDER_OTHER_CODE },
  ];

  getModalKey() {
    return Math.random();
  }

  async mounted() {
    await this.getUser();
  }

  async getUser() {
    this.editUser = await this.fetchUser(this.$route.params.userGuid);
  }

  getNameSurname() {
    if (!_.isNil(this.editUser)) {
      return this.editUser.name + " " + this.editUser.surname;
    }
  }

  getUserRole() {
    if (!_.isNil(this.editUser)) {
      if (!_.isNil(this.editUser.role)) {
        return this.editUser.role?.des;
      }
    }
  }

  getReferentName() {
    if (this.hasReferent()) {
      return getReferentName(this.editUser);
    } else return "Referente CNA ancora non associato";
  }

  hasReferent() {
    return getReferentName(this.editUser) != "";
  }

  getAssociated() {
    if (!_.isNil(this.editUser)) {
      if (this.editUser.associated) {
        return "Utente associato";
      } else {
        return "Utente non associato";
      }
    }
    return "";
  }

  getCompanyName() {
    if (!_.isNil(this.editUser)) {
      if (!_.isNil(this.editUser.company)) {
        return this.editUser.company.ragsoc;
      }
      return "";
    }
  }

  getGenderValue() {
    if (!_.isNil(this.editUser)) {
      return getGenderText(this.editUser.gender);
    }
    return "";
  }

  getUserToEdit() {
    return _.clone(this.editUser);
  }

  isReferent() {
    return isCompanyReferent(this.editUser.status?.code);
  }

  isAdminLevel1() {
    return checkUserRole(this.user, [RoleCodes.ADMIN1]);
  }

  editingUserIsAdminLevel1() {
    return checkUserRole(this.editUser, [RoleCodes.ADMIN1]);
  }

  showItem(roleCodes: RoleCodes[]) {
    return checkUserRole(this.editUser, roleCodes);
  }

  hasAdminArea() {
    const areas = this.getArea([this.editUser.macroarea_guid]);
    return !_.isEmpty(areas);
  }

  getAdminArea() {
    if (this.hasAdminArea()) {
      return this.getArea([this.editUser.macroarea_guid])[0].des;
    } else {
      return "Non è stata associata nessun area";
    }
  }

  getAdminSubGroups() {
    if (this.hasAdminSubGroups()) {
      let text = "";
      _.forEach(
        this.getSubGroup(this.editUser.macroarea_sottogruppo),
        (group, index) => {
          if (parseInt(index) == 0) {
            text = group.des;
          }
          if (parseInt(index) > 0) {
            text = text + " - " + group.des;
          }
        }
      );
      return text;
    } else {
      return "Non è stato associato nessun sottogruppo";
    }
  }

  hasAdminSubGroups() {
    const subGroups = this.getSubGroup(this.editUser.macroarea_sottogruppo);
    return !_.isEmpty(subGroups);
  }

  hasHeadquarter() {
    return !_.isNil(this.editUser.headquarter_guid);
  }

  getHeadquarter() {
    if (this.hasHeadquarter()) {
      return this.editUser.headquarter.city;
    } else {
      return "Non è stata associata nessuna sede";
    }
  }

  async openEditAreaModal() {
    const areas = this.getArea([this.editUser.macroarea_guid]);
    if (!_.isEmpty(areas)) {
      await this.setSelectedArea(areas[0]);
    }
    this.showAreaSelectorModal = true;
  }

  async updateArea(area) {
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation put($guid: ID!, $area: String) {
          updateUser(guid: $guid, macroarea_guid: $area)
        }
      `,
      variables: {
        guid: this.editUser.guid,
        area: area.guid,
      },
    });
    if (res.data.updateUser) {
      await this.getUser();
      this.showSuccess("Area aggiornata correttamente");
    } else {
      this.showError("Errore durante aggiornamento Area");
    }
  }

  async openEditGroupsModal() {
    const areas = this.getArea([this.editUser.macroarea_guid]);
    if (!_.isEmpty(areas)) {
      await this.setSelectedArea(areas[0]);
      await this.setSelectedGroups(
        this.getSubGroup(this.editUser.macroarea_sottogruppo)
      );
    }
    this.showGroupsSelectorModal = true;
  }

  async updateGroups() {
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation put($guid: ID!, $groups: [String]) {
          updateUser(guid: $guid, macroarea_sottogruppo: $groups)
        }
      `,
      variables: {
        guid: this.editUser.guid,
        groups: _.map(this.selectedGroups, (group) => {
          return group.guid;
        }),
      },
    });
    if (res.data.updateUser) {
      await this.getUser();
      this.showSuccess("Sottogruppi aggiornati correttamente");
    } else {
      this.showError("Errore durante aggiornamento Sottogruppi");
    }
  }

  async openEditHeadquarterModal() {
    await this.setSelectedHeadquarter(this.editUser.headquarter);
    this.showHeadquarterSelectorModal = true;
  }

  async updateHeadquarter(headquarter) {
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation put($guid: ID!, $headquarter: String) {
          updateUser(guid: $guid, headquarter_guid: $headquarter)
        }
      `,
      variables: {
        guid: this.editUser.guid,
        headquarter: headquarter.guid,
      },
    });
    if (res.data.updateUser) {
      await this.getUser();
      this.showSuccess("Sede di competenza aggiornata correttamente");
    } else {
      this.showError("Errore durante aggiornamento Sede di competenza");
    }
  }
}
