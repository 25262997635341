
import HeadquarterSelector from "@/components/HeadquarterSelector.vue";
import Modal from "@/components/Modal.vue";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {
    Modal,
    HeadquarterSelector,
  },
})
export default class HeadquarterSelectorModal extends Vue {

  selectedHeadquarter = null;

  save() {
    this.$emit("selected", this.selectedHeadquarter);
    this.onClose();
  }

  onClose() {
    this.$emit("close");
  }
}
