
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import UserData from "@/components/UserData.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { Btn, Card, UserData },
})
export default class ManageUsersDetailsView extends Vue {

  @Prop()
  isEducationSection: boolean

}
