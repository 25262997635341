
import AreaSelector from "@/components/AreaSelector.vue";
import CompaniesSelector from "@/components/CompaniesSelector.vue";
import DatePicker from "@/components/DatePicker.vue";
import GenderSelector from "@/components/GenderSelector.vue";
import Modal from "@/components/Modal.vue";
import Btn from "@/components/Btn.vue";
import SelectedServices from "@/components/SelectedServices.vue";
import ServicesSelector from "@/components/ServicesSelector.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import { parseDateFromApi } from "@/plugins/moment";
import * as Api from "@/types/graphql";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import gql from "graphql-tag";
import _ from "lodash";
import validator from "validator";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action, Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import { associateData, TAX_CODE_REGEX } from "../utils/user";

@Component({
  components: {
    Modal,
    ServicesSelector,
    AreaSelector,
    SelectedServices,
    UsersSelector,
    CompaniesSelector,
    GenderSelector,
    DatePicker,
    Btn
  },
  watch: {},
})
export default class EditUserModal extends Vue {
  @State((state: StateStore) => state.data.selectedServices)
  selectedServices: Api.Service[];
  @Getter getStatus: (code: string) => Api.UserStatus;
  @Getter getRole: (code: string) => Api.Role;
  @Action fetchRolesList;
  @Action updateUsersServices;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation removeSelectedService;
  @Mutation resetSelectedServices;
  @Mutation setSelectedUsers;
  @Mutation resetSelectedUsers;

  isFormValid = false;
  allowSave = false;

  @Prop()
  user;

  editUser = null;

  associatedItems = associateData;

  nameRules = [(v) => !!v || "Inserire un nome"];
  surnameRules = [(v) => !!v || "Inserire il cognome"];
  emailRules = [
    //(v) => !!v || "Inserire la mail",
    //(v) => !!validator.isEmail(v ? v : "") || "Email non valida",
  ];
  phoneRules = [];
  genderRules = [(v) => !!v || "Seleziona un valore"];
  birthdayRules = [(v) => !!v || "Inserire la data di nascita"];
  taxCodeRules = [
    (v) => !!v || "Inserire il codice fiscale",
    (v) => !!v.match(TAX_CODE_REGEX) || "Codice fiscale non valido",
  ];
  associatedRules = [(v) => v == 0 || v == 1 || "Seleziona un opzione"];

  async mounted() {
    // this.resetValues();
    this.editUser = this.user;

    if (!_.isNil(this.user["birth_date"])) {
      this.editUser["birth_date"] = parseDateFromApi(this.user["birth_date"]);
    }
    if (!_.isNil(this.user["associated_year"])) {
      this.editUser["associated_year"] = parseDateFromApi(
        this.user["associated_year"]
      );
    }
  }

  onUserAssociatedChange(associated) {
    this.editUser.associated = associated;
    if (!associated) {
      this.editUser["associated_year"] = null;
    }
  }

  isAdmin() {
    return checkUserRole(this.user, [RoleCodes.ADMIN1, RoleCodes.ADMIN2]);
  }

  onClose() {
    this.allowSave = false;
    this.$emit("close");
  }

  async updateUser() {
    if (
      (this.$refs.editUserForm as Vue & {
        validate: () => boolean;
      }).validate()
    ) {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation user_update(
            $guid: ID!
            $email: String
            $name: String
            $surname: String
            $phone: String
            $birthday: String
            $gender: String
            $taxCode: String
            $associated: Int
            $associatedYear: Date
          ) {
            updateUser(
              guid: $guid
              email: $email
              name: $name
              surname: $surname
              phone: $phone
              birth_date: $birthday
              gender: $gender
              tax_code: $taxCode
              associated: $associated
              associated_year: $associatedYear
            )
          }
        `,
        variables: {
          guid: this.editUser.guid,
          email: this.editUser.email,
          name: _.capitalize(this.editUser.name),
          surname: _.capitalize(this.editUser.surname),
          phone: this.editUser.phone,
          birthday: this.editUser["birth_date"],
          gender: this.editUser.gender,
          taxCode: this.editUser["tax_code"],
          associated: this.editUser.associated,
          associatedYear: this.editUser["associated_year"],
        },
      });

      if (res.data.updateUser) {
        this.$emit("userUpdated", res.data.updateUser);
        this.showSuccess("Utente modificato correttamente");
        this.onClose();
      } else {
        this.showError("Errore durante modifica utente");
      }
    }
  }
}
